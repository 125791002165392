import { render, staticRenderFns } from "./interactiveqa.vue?vue&type=template&id=89e40496&scoped=true&"
import script from "./interactiveqa.vue?vue&type=script&lang=js&"
export * from "./interactiveqa.vue?vue&type=script&lang=js&"
import style0 from "./interactiveqa.vue?vue&type=style&index=0&id=89e40496&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e40496",
  null
  
)

export default component.exports